import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import TextField from "@material-ui/core/TextField";
import withContext from "../../../WithContextFile";
import "./ModifyAccount.scss";
import AddressService, { buildCap } from "../../FormComponents/AddressComponent";
import addressServiceGetData from "../../FormComponents/addressService";
import requests from "../../../Services/HttpServices";
import validate from "../../../formValidationService";
import PhoneNumber from "../../FormComponents/PhoneNumber";
import UserFormData from "../../FormComponents/userFormData";
import FeedbackModal from "../../FormComponents/FeedbackModal";
import ModifyAccountPartnerExtraField from "./ModifyAccountPartnerExtraField";

let initPhone = null;
let initEmail = null;

class ModifyAccount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			resized : false,
			form    : {
				name     : "",
				surname  : "",
				phone    : "",
				email    : "",
				city     : "",
				province : "",
				route    : "",
				civic    : "",
				cap      : "",
				username : "",
				region   : "",
				CF       : "",
			},
			vat           : "",
			feedbackModal : false,
			modalResult   : "",
			modalText     : "",
			formError     : {},
		};
	}

	setInitialData() {
		const temp = this.state.form;

		temp.name             = this.state.loggedUser.name;
		temp.username         = this.state.loggedUser.username;
		temp.surname          = this.state.loggedUser.surname || "Cognome";
		temp.email            = this.state.loggedUser.email;
		temp.CF               = this.state.loggedUser.cf;
		initEmail             = this.state.loggedUser.email;
		temp.phone            = this.state.loggedUser.telephone;
		initPhone             = this.state.loggedUser.telephone;
		temp.route            = this.state.userAddress.address;
		temp.civic            = this.state.userAddress.number;
		temp.city             = this.state.userAddress.city;
		temp.province         = this.state.userAddress.province;
		temp.cap              = this.state.userAddress.cap;
		temp.region           = this.state.userAddress.region;
		temp.nationality      = this.state.userAddress.nationality;
		temp.complete_address = this.state.userAddress.complete_address;

		let radioContact;
		try {
			radioContact = this.state.loggedUser.preferred_contact.type;
		} catch (e) {
			radioContact = "";
		}

		this.setState({
			form       : temp,
			radioValue : radioContact,
			setted     : true,
		});
	}

	componentDidMount() {
		let doit;
		window.onresize = () => {
			clearTimeout(doit);
			doit = setTimeout(() => {
				this.setState({ resized: !this.state.resized });
			}, 100);
		};
	}

	handleChange = (e) => {
		const { form }      = this.state;
		form[e.target.name] = e.target.value;
		let { formError }   = this.state;
		formError           = validate(e.target.value, e.target.name, formError);
		if (e.target.name === "CF" && !this.props.partner) {
			formError.CF = e.target.value && e.target.value.length !== 16;
		}
		if (e.target.name === "email" && !initEmail && e.target.value === "") {
			formError.email = false;
		}
		this.setState({
			form,
			formError,
		});
	};

	send(obj) {
		let error         = false;
		let { formError } = this.state;
		for (const property in this.state.form) {
			if (
				(property !== "phone"
					|| (this.state.form.phone && this.state.form.phone.length >= 9))
				&& (property !== "email" || this.state.form.email !== "")
				&& (property !== "CF" || this.state.form.nationality == "105")
			) {
				if (
					!(
						property === "address"
						|| property === "location"
						|| property === "complete_address"
					)
					|| this.state.form.nationality != "105"
				) {
					if (property !== "email") {
						formError = validate(
							this.state.form[property],
							property,
							formError,
						);
						if (this.props.partner) {
							formError.CF = this.state.form.CF !== "" && this.state.form.CF.length !== 16;
						}
						error = error || formError[property];
					} else if (initEmail) {
						formError = validate(
							this.state.form[property],
							property,
							formError,
						);
						error     = error || formError[property];
					}
				}
			}
		}
		if (this.props.partner && this.state.vat.length <= 0) {
			error = true;
		}
		if (
			initPhone
			&& ((this.state.form.phone
					&& this.state.form.phone.length < 8
					&& this.state.form.phone.length > 4)
				|| (!this.state.form.phone && !this.state.form.email))
		) {
			formError.phone = true;
			error           = true;
			this.setState({
				feedbackModal : true,
				modalResult   : "Errore",
				modalText     : "Numero di telefono non valido",
			});
		}
		if (this.state.form.region && !this.state.form.route && error) {
			this.setState({
				feedbackModal : true,
				modalResult   : "Errore",
				modalText     : "Devi inserire tutti i dati dell'indirizzo",
			});
		}
		this.setState({ load: !this.state.load });
		!error
		&& requests("post", "modify", obj)
			.then((result) => {
				if (result.response === "success") {
					const address = {};
					sessionStorage.setItem("user", JSON.stringify(result.session));
					sessionStorage.setItem("User", JSON.stringify(result.session));
					if (
						result.session.userdata.addresses
						&& result.session.userdata.addresses.length > 0
					) {
						address.address          = result.session.userdata.addresses[
						result.session.userdata.addresses.length - 1
							].address;
						address.number           = result.session.userdata.addresses[
						result.session.userdata.addresses.length - 1
							].number;
						address.region           = result.session.userdata.addresses[
						result.session.userdata.addresses.length - 1
							].region;
						address.city             = result.session.userdata.addresses[
						result.session.userdata.addresses.length - 1
							].location;
						address.province         = result.session.userdata.addresses[
						result.session.userdata.addresses.length - 1
							].province;
						address.cap              = result.session.userdata.addresses[
						result.session.userdata.addresses.length - 1
							].cap;
						address.nationality      = result.session.userdata.addresses[
						result.session.userdata.addresses.length - 1
							].nationID;
						address.complete_address = result.session.userdata.addresses[
						result.session.userdata.addresses.length - 1
							].completeAddress;
					}
					sessionStorage.setItem("UserAddress", JSON.stringify(address));
					this.setState({
						feedbackModal : true,
						modalResult   : "Successo",
						modalText     : "Account modificato correttamente",
					});
				} else if (result.message) {
					this.setState({
						feedbackModal : true,
						modalResult   : "Errore",
						modalText     : result.message,
					});
				} else {
					this.setState({
						feedbackModal : true,
						modalResult   : "Errore",
						modalText     : "Errore generico",
					});
				}
			});
	}

	render() {
		try {
			if (!this.state.loggedUser && sessionStorage.getItem("User")) {
				this.setState({
					loggedUser: JSON.parse(sessionStorage.getItem("User")).userdata,
				});
				this.setState({
					userAddress: JSON.parse(sessionStorage.getItem("UserAddress")),
				});
			}
		} catch (e) {

		}

		if (
			this.state.loggedUser
			&& this.state.form.name == ""
			&& !this.state.setted
		) {
			this.setInitialData();
			this.state.userAddress
			&& addressServiceGetData("province", this.state.userAddress.region)
				.then(
					(resolve) => {
						this.setState({ provincie: resolve });
						addressServiceGetData(
							"location",
							this.state.userAddress.province,
						)
							.then((resolve) => {
								const locSelected = resolve.find(
									(x) => x.ID === (this.state.form.location || this.state.form.city),
								);
								let capArr        = [];
								if (locSelected.capList) capArr = buildCap(locSelected);
								capArr.unshift(locSelected.cap);
								this.setState({
									comuni : resolve,
									caps   : capArr,
								});
							});
					},
				);
		}

		return !this.props.context.user ? (
			<>
				<form
					onSubmit={(event) => {
						event.preventDefault();
						const formData = new FormData(event.target);
						const object   = {};
						// formData.set('editor', this.state.editor)
						formData.forEach((value, key) => {
							object[key] = value;
						});
						this.send(object);
					}}
					className="reset-password-form modify"
					id="modify-account"
					style={{
						width     : 400,
						boxShadow : "black",
					}}
				>
					{this.state.feedbackModal && (
						<FeedbackModal
							result={this.state.modalResult}
							close={() => {
								this.setState({
									feedbackModal : false,
									modalResult   : "",
									modalText     : "",
								});
								this.state.modalResult !== "Errore" && window.location.reload();

								// this.setState({name: '',number:'',selectedValue:''})
							}}
							label={this.state.modalText}
						/>
					)}
					<UserFormData
						notusername
						ismodify
						partnerModify={this.props.partner}
						modify={window.innerWidth > 650}
						nationalityChange={() => {
							this.setState({ reload: true });
						}}
						load={() => {
							this.setState({ load: true });
						}}
						openModalEmail={(data) => {
							this.setState({
								existModal : true,
								modalname  : data,
							});
						}}
						names="modify"
						form={this.state.form}
						formError={this.state.formError}
					/>
					{this.props.partner && (
						<ModifyAccountPartnerExtraField
							azienda={this.props.azienda}
							setVat={(vat) => this.setState({ vat })}
							vat={this.state.vat}
						/>
					)}
					<label className="buy-form-paragraph">Dati indirizzo</label>
					<AddressService
						names="modify"
						state={this.state}
						form={this.state.form}
						formError={this.state.formError}
						nationality={this.state.form.nationality || "105"}
						province={this.state.provincie}
						comuni={this.state.comuni}
						modifyAccount
						caps={this.state.caps}
					/>
					<div className="field is-clearfix">
						<button className="button is-primary is-outlined is-pulled-right mt-16">
							Salva
						</button>
					</div>
				</form>
			</>
		) : (
			<Redirect to="/products" />
		);
	}
}

export default withContext(ModifyAccount);
