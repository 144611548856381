import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

export default function SuccessBuy(props) {
  const history = useHistory();
  const flag = history.location.query && history.location.query.isMemorial;
  if (!flag && !(history.location.query && history.location.query.data))
    history.push("/home");

  let label;
  if (props.isMemorial || flag)
    label =
      "Una volta verificata la richiesta il servizio memorial verra` attivato automaticamente dal sistema.";
  else if (history.location.query.dedicaAutoAttivata)
    label = "La dedica è stata attivata automaticamente, senza bisogno di un approvazione o di un pagamento.";
  else
    label = `Una volta avallata la richiesta dal proprietario della pagina, il dono verra\` pubblicato nella pagina del defunto.`;

  const mainLabel = history.location.query.dedicaAutoAttivata
    ? "Dedica Attivata Automaticamente"
    : "Grazie dell'acquisto";

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: 700 }}>
      <label
        className="death-data"
        style={{ margin: "0 auto", marginBottom: 20 }}
      >
        {mainLabel}
      </label>
      <label>{label}</label>
      {!(props.isMemorial || flag) && history?.location?.query?.deceasedID && (
        <Link to={`./memorial?${history?.location?.query?.deceasedID}`}>
          Clicca qui per tornare alla pagina del defunto
        </Link>
      )}
    </div>
  );
}
